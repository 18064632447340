import React, { useEffect } from "react";
import SubHeadingOther from "../../../common/components/SubHeadingOther";
import {
  Form,
  Button as BButton,
  Button as BsButton,
  InputGroup,
} from "react-bootstrap";
import { useState } from "react";
import Select from "react-select";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services/index";
import MessageHandler from "../../../common/components/MessageHandler";
import {
  FormatDate,
  prepareOptions,
  printConsole,
  relatedToListData,
} from "../../../common/helper/Common";
import { toast } from "react-toastify";
// import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import dayjs from "dayjs";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonDatePicker from "../../../common/components/date-picker/CommonDatePicker";
import { DecryptToken } from "../../../common/helper/BasicFn";
import GlobalDropdown from "../../../common/components/custom-ui/GlobalDropdown";
import {
  addToHistoryStack,
  removeBreadcrumb,
} from "../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function NewSupport() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    account_id: "",
    aed_id: "",
    contact_id: "",
    site_id: "",
    issue_type: "",
    assign_to: "",
    related_to: "",
    relation: "",
    due_date: "",
    issue: "",
    owner_name: "",
  });

  const [selectedData, setSelectedData] = useState({});
  const [relatedToList, setRelatedToList] = useState(relatedToListData);
  const [issueTypeList, setIssueTypeList] = useState([
    { label: "Issue 1", value: "1" },
  ]);
  /***    { label: 'Issue 1', value: '1' },
          { label: 'Issue 2', value: '2' },
          { label: 'Issue 3', value: '3' },
       */
  /**    { label: 'Contact 1', value: '1' },
          { label: 'Contact 2', value: '2' },
          { label: 'Contact 3', value: '3' },
       */
  const [assignedToList, setAssignedToList] = useState([]);
  const [FormMsg, setFormMsg] = React.useState({ type: true, msg: "" });
  const [DefaultContact, setDefaultContact] = useState();
  const navigate = useNavigate();
  const { accountId, sectionName } = useParams();

  const user = DecryptToken();
  const dispatch = useDispatch();
  const { siteId, siteName } = useParams();
  const location = useLocation();

  const locationStateCheck = location?.state;

  const handleInputChange = (e) => {
    setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const paramValue = urlParams.get("paramName");
  printConsole({ paramValue }); // print console.log

  // Fetch all parameters
  urlParams.forEach((value, key) => {});
  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );
  const handleRemoveDispatch = (is_active) => {
    if (is_active) {
      const editPageUrl = "account/new-support";
      dispatch(removeBreadcrumb(editPageUrl));
    } else {
      const lastBreadcrumb =
        breadcrumbsFromStore[breadcrumbsFromStore.length - 1];
      dispatch(addToHistoryStack(lastBreadcrumb));
      const editPageUrl = "account/new-support";
      dispatch(removeBreadcrumb(editPageUrl));
    }
  };
  // handle select change
  const handleSelectChange = (data, key) => {
    setSelectedData((old) => ({
      ...old,
      [key]: {
        value: data.value,
        label: data.label,
      },
    }));
    // DefaultContact

    setFormData((old) => ({ ...old, [key]: data.value }));
  };
  const handleSelectissueChange = (data, key) => {
    setSelectedData((old) => ({
      ...old,
      [key]: {
        value: data,
      },
    }));
    // DefaultContact

    setFormData((old) => ({ ...old, [key]: data }));
  };

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      formData?.issue_type == "" ||
      (user?.user_type == 3
        ? formData?.assign_to == ""
        : formData?.assign_to !== "")
    ) {
      setValidated(true);
    }

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    // save the form data
    saveData();
  };
  const [loading, setLoading] = useState(false);
  const saveData = async (e) => {
    setLoading(true);
    let payLoadData;
    printConsole({ ids: formData?.issue_type }); // print console.log

    // if (location.state.type === "Support") {
    //     payLoadData = {
    // account_id: locationStateCheck?.accountId,
    //         contact_id: locationStateCheck?.contactId,
    //         site_id: locationStateCheck?.siteId,
    //         // inperson_id: locationStateCheck?.inpersonId,
    //         aed_id: locationStateCheck?.aedId,
    //         issue_type: formData?.issue_type ?? 1,
    //         assign_to: user?.user_type != 3 ? (formData?.assign_to ?? 1) : undefined,
    //         related_to: locationStateCheck?.type ?? 1,
    //         relation: "",
    //         due_date: user?.user_type == 3 ? "" : (formData?.due_date ?? ""),
    //         issue: formData?.issue ?? "",
    //     };
    // }
    if (location.state.type === "Support") {
      payLoadData = {
        account_id: locationStateCheck?.accountId,
        contact_id: locationStateCheck?.contactId,
        site_id: locationStateCheck?.siteId,
        // inperson_id: locationStateCheck?.inpersonId,
        aed_id: locationStateCheck?.aedId,
        issue_type: formData?.issue_type ?? 1,
        related_to: locationStateCheck?.type ?? 1,
        relation: "",
        due_date: formData?.due_date ?? "",
        issue: formData?.issue ?? "",
      };

      // Conditionally add assign_to only if user_type is not 3
      if (user?.user_type != 3) {
        payLoadData.assign_to = formData?.assign_to ?? 1;
      }
    } else {
      payLoadData = {
        account_id: locationStateCheck?.accountId,
        contact_id: locationStateCheck?.contactId,
        issue_type: formData?.issue_type ?? 1,
        // assign_to: user?.user_type != 3 ? (formData?.assign_to ?? 1) : undefined,
        related_to: locationStateCheck?.type ?? 1,
        relation: locationStateCheck?.support_name ?? "",
        due_date: user?.user_type == 3 ? "" : formData?.due_date ?? "",
        issue: formData?.issue ?? "",
        owner_name: formData?.owner_name ?? "",
        status: 0,
        site_id: locationStateCheck?.site_id ?? 0,
        inperson_id: locationStateCheck?.inperson_id ?? 0,
        aed_id: locationStateCheck?.aedId ?? 0,
      };
      if (user?.user_type != 3) {
        payLoadData.assign_to = formData?.assign_to ?? 1;
      } else {
        payLoadData.assign_to = "";
      }
    }

    let result = await CallPOSTAPI("support/create-ticket", payLoadData);
    printConsole({ result }); // print console.log
    setFormMsg({ type: result?.data?.status, msg: result?.data?.message });
    setLoading(false);
    handleRemoveDispatch();
    if (result?.data?.status) {
      toast.success("Ticket Added Successfully");

      let redirectUrl = "";
      if (
        user?.user_type == 3 ||
        (user?.user_type == 2 && user?.sub_admin == "")
      ) {
        redirectUrl =
          "/account-details/" + locationStateCheck?.accountId + "/Support";
      } else {
        redirectUrl =
          "/account-details/" + locationStateCheck?.accountId + "/Support";
      }
      if (locationStateCheck.type == "account") {
        let pathUrl = "";
        if (
          (user?.user_type == 2 && user?.sub_admin == "") ||
          user?.user_type == 3
        ) {
          pathUrl =
            "/account-details/" + locationStateCheck?.accountId + "/Support";
        } else {
          pathUrl =
            "/account-details/" + locationStateCheck?.accountId + "/Support";
        }

        navigate(pathUrl, {
          state: {
            tab: "Details",
            type: result?.data?.status,
            msg: result?.data?.msg,
          },
        });
      } else if (payLoadData?.site_id) {
        navigate("/account/site-details/" + payLoadData?.site_id, {
          state: {
            tab: "Details",
            type: result?.data?.status,
            msg: result?.data?.msg,
          },
        });
      }
      // else if (payLoadData?.aed_id) {
      //     navigate("account/" + payLoadData?.accountId + "/contact-details" + payLoadData?.aed_id, {
      //         state: {
      //             tab: "Support",
      //             type: result?.data?.status,
      //             msg: result?.data?.msg,
      //         },
      //     });

      // }
      // else if (payLoadData?.contact_id) {
      //     navigate("/account/aed-details/" + payLoadData?.contact_id, {
      //         state: {
      //             tab: "Support",
      //             type: result?.data?.status,
      //             msg: result?.data?.msg,
      //         },
      //     });
      // }
      else {
        navigate(redirectUrl, {
          state: {
            tab: "Support",
            type: result?.data?.status,
            msg: result?.data?.msg,
          },
        });
      }
    } else {
      toast.error("Something went wrong please try again");
    }
  };

  // fetch on load
  // const fetchOnLoad = async () => {
  //     const result = await CallGETAPI("aed/all-issues-type");

  //     if (result?.status) {
  //         const issueTypes = result?.data?.data?.issuesList;
  //         console.log({ issueTypes })
  //         const allIssueTypes = prepareOptions(
  //             issueTypes,
  //             "issue_id",
  //             "issue_name"
  //         );

  //         setIssueTypeList(allIssueTypes);
  //     }
  // };

  useEffect(() => {
    // fetchOnLoad();
  }, []);

  // pass location value
  const passLocation = async (state) => {
    if (state?.type == "account") {
      setFormData((old) => ({
        ...old,
        ["relation"]: "Account: " + state?.accountName,
        ["related_to"]: "Account",
      }));
      setSelectedData((old) => ({
        ...old,
        ["related_to"]: {
          label: "Account",
          value: "Account",
        },
      }));
    } else if (state?.type == "contacts") {
      setFormData((old) => ({
        ...old,
        ["relation"]: "Contacts: " + state?.contactName,
        ["related_to"]: "Contacts",
      }));
      setSelectedData((old) => ({
        ...old,
        ["related_to"]: {
          label: "Contacts",
          value: "Contacts",
        },
      }));
    } else if (state?.type == "site") {
      setFormData((old) => ({
        ...old,
        ["relation"]: "Site: " + state?.siteName,
        ["related_to"]: "Site",
      }));
      setSelectedData((old) => ({
        ...old,
        ["related_to"]: {
          label: "Site",
          value: "Site",
        },
      }));
    } else {
      setFormData((old) => ({
        ...old,
        ["relation"]: "Contact: " + state?.contactId,
        ["related_to"]: "Contact",
      }));
      setSelectedData((old) => ({
        ...old,
        ["related_to"]: {
          label: "Contact",
          value: "Contact",
        },
      }));
    }
  };

  useEffect(() => {
    if (location?.state) {
      passLocation(location?.state);
    }
  }, []);

  // prepare owner name data
  const prepareOwnerName = (data) => {
    setFormData((old) => ({ ...old, ["owner_name"]: data?.label }));
  };

  const fetchAssignToList = async (issue, key) => {
    const result = await CallGETAPI("support/assign-by-issues/" + issue);

    if (result?.status) {
      const assignToList = result?.data?.data?.contactList;
      const default_admin = result?.data?.data?.default_admin;
      assignToList?.map((contact) => {
        contact.owner_name =
          contact?.account_main_contact_firstname +
          " " +
          contact.account_main_contact_lastname;
      });

      const allAssignToList = prepareOptions(
        assignToList,
        "account_main_contact_id",
        "owner_name"
      );
      setAssignedToList(allAssignToList);
      const matchingContact = assignToList?.filter(
        (contact) => contact.account_main_contact_id === default_admin
      );
      let ownerName = prepareOptions(
        matchingContact,
        "account_main_contact_id",
        "owner_name"
      );
      setSelectedData((prev) => ({
        ...prev,
        [key]: {
          label: ownerName[0]?.label,
          value: ownerName[0]?.value,
        },
      }));
      setFormData((prev) => ({ ...prev, [key]: ownerName[0]?.value }));
      // selectedData?.issue_type =

      setDefaultContact(default_admin);
    }
  };

  // calendar icon
  const calendarIcon = () => {
    return <img src="/calendar.svg" alt="calendar" />;
  };

  // handle calendar change
  const handleCalendarChange = (name, date) => {
    let dateValue = null;
    if (date) {
      // setValidatedContract(false);
      // const formattedDate = dayjs(date).format("MM/DD/YYYY");
      const formattedDate = date ? FormatDate(date) : "";
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedDate,
      }));
    }
  };
  // const matchedContact = DefaultContact?.map(defaultItem => {

  //     const match = assignedToList.find(contact => contact.value === defaultItem.value);

  //     return match
  // });

  // const handleRemoveDispatch = () => {
  //     console.log("asdfghgfdsasdfgfdsasdfgfdsdfgfdfg", `/move-aed/${accountId}/${siteId}`)
  //     const editPageUrl = `/move-aed/${accountId}/${siteId}`
  //     dispatch(removeBreadcrumb(editPageUrl));

  // }
  return (
    <>
      <div className="mt-4 mb-4" style={{ width: "100%" }}>
        <SubHeadingOther
          hideNew="tab"
          title={
            "New Support Ticket : " +
            location?.state?.type +
            " " +
            location?.state?.support_name
          }
          newUrl=""
          subHeading={true}
          hideHierarchy={true}
          bottomLinks={false}
        />

        {/* main form */}
        <Form
          className=""
          onSubmit={handleSubmit}
          noValidate
          validated={validated}
          id="create-edit-support-form"
        >
          <div className="containerr">
            <div className="">
              <div
                className="container-fluid mt-4 bottom-border-blue pt-2"
                style={{
                  borderBottom: "4px solid rgb(13, 110, 253)",
                  background: "#eee",
                }}
              >
                <h2 className="heading">General Information</h2>

                <div className="row my-3">
                  <Form.Group className={"col"}>
                    <Form.Label>Issue Type*</Form.Label>
                    <GlobalDropdown
                      dropDownName={"issue_id"}
                      apiEndpoint={"aed/all-issues-type"}
                      idKey={"issue_id"}
                      valueKey={"issue_name"}
                      parentKey={"issuesList"}
                      onSelect={(e, issue_id) => {
                        handleSelectissueChange(issue_id, "issue_type");
                        if (user?.user_type !== 3) {
                          fetchAssignToList(issue_id, "assign_to");
                        }
                      }}
                      isCache={true}
                    />
                  </Form.Group>
                  {user?.user_type == 3 ? (
                    " "
                  ) : (
                    <Form.Group className={"col"}>
                      <Form.Label>Assigned to*</Form.Label>

                      <Select
                        value={
                          user?.user_type == 3 ? "" : selectedData?.assign_to
                        }
                        options={assignedToList}
                        onChange={(data) => {
                          handleSelectChange(data, "assign_to");
                          prepareOwnerName(data);
                        }}
                        required
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor:
                              validated && formData?.assign_to === ""
                                ? "#DC3545"
                                : "transparent",
                          }),
                          // Customize the selected option and hover state
                          option: (provided, state) => ({
                            ...provided,
                            color:
                              state.data.value === DefaultContact
                                ? "blue"
                                : "black",
                            backgroundColor: state.isSelected
                              ? "#e9ecef"
                              : "white",
                            "&:hover": {
                              color: state.isSelected ? "white" : "white", // White text on hover if selected
                              backgroundColor: "#007bff", // Change hover background color if needed
                            },
                          }),
                          singleValue: (provided, state) => ({
                            ...provided,
                            color: "black", // Ensure black text for the selected value
                          }),
                        }}
                      />
                      {validated && formData?.assign_to == "" && (
                        <>
                          <p className="invalid">This field is required</p>
                        </>
                      )}
                    </Form.Group>
                  )}

                  {/* <Form.Group className={ "col" }>
                                        <Form.Label>Related to</Form.Label>
                                        <Select
                                            value={selectedData?.related_to}
                                            options={ relatedToList }
                                            onChange={ (data) => { handleSelectChange(data, 'related_to') } }
                                        />
                                    </Form.Group> */}

                  {/* <Form.Group className={ "col" }>
                                        <Form.Label>Relation</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="relation"
                                            value={ formData?relation}
                                            onChange={ handleInputChange }
                                        />
                                    </Form.Group> */}
                  {user?.user_type == 3 ? (
                    ""
                  ) : (
                    <Form.Group
                      className={"col"}
                      style={{ minWidth: "490px", marginRight: "2px" }}
                    >
                      <Form.Label>Due Date</Form.Label>
                      <div
                        style={{ maxWidth: "200px" }}
                        className={
                          validated.due_date
                            ? "d-flex align-items-center calendar-input-btn invalid-datepicker-div"
                            : "d-flex align-items-center calendar-input-btn"
                        }
                      >
                        <CommonDatePicker
                          calName={"due_date"}
                          CalVal={
                            formData?.due_date
                              ? FormatDate(formData?.due_date)
                              : null
                          }
                          HandleChange={(name, val) =>
                            handleCalendarChange(name, val)
                          }
                          disabled={false}
                        />
                      </div>
                    </Form.Group>
                  )}
                </div>

                <div className="row my-4">
                  <Form.Group className={"col"}>
                    <Form.Label>Issue*</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="issue"
                      value={formData?.issue}
                      onChange={handleInputChange}
                      required
                      rows={5}
                    />

                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                {/* message */}
                <div className="my-5">
                  <MessageHandler
                    status={FormMsg.type}
                    msg={FormMsg.msg}
                    HandleMessage={setFormMsg}
                  />
                </div>

                {/* bottom buttons */}
                <div className="row pb-3">
                  <div className="col-12 content-flex-right">
                    <button
                      className="btn btn-danger text-uppercase"
                      type="button"
                      onClick={() => {
                        const is_active = 0;
                        handleRemoveDispatch(is_active);
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-success text-uppercase ms-2"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
